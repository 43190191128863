import React, { useEffect, useState } from "react";
import styles from "./LoanApplication.module.scss";
import CustomSelectDropdown from "../CustomHTMLElements/CustomSelectDropdown";
import NoTableDataAvailable from "../NoTableDataAvailable/NoTableDataAvailable.component";
import Paginator from "../Paginator/Paginator.component";
import { useHistory } from "react-router-dom";
import { useAuth } from "../../Context/auth.context";
import { v4 as uuidv4 } from "uuid";
import useLoanApplicationTableList from "../../custom-hooks/useLoanApplicationTableList";
import PageLoader from "../PageLoader/PageLoader.component";
import { StatusTag } from "../../helpers/attachClassNameToTag";
import { encrypt } from "../../helpers/encryptor";
import { formatMoney } from "../../helpers/formatter";
import useEligibilityStatus from "../../custom-hooks/useEligibilityStatus";
import DismissableAlert from "../../components/DismissableAlert/DismissableAlert.component";

const LoanApplicationTableList = () => {
  const [count, setCount] = useState<number | null>(null);

  const [preState, setPreState] = useState({
    page: 1,
    PageSize: 10,
  });

  let history = useHistory();

  const {
    aggregatorDetails,
    setEditLoanApplicationGlobal,
    setCurrentLoanApplicationId,
    editLoanApplicationGlobal,
    setContinueLoanRequestProgressBar,
  } = useAuth();

  const {
    data: LoanApplicationListData,
    status: LoanApplicationListStatus,
    error: LoanApplicationListError,
  } = useLoanApplicationTableList(preState);
  const { data: EligibilitiyStatusData } = useEligibilityStatus(
    aggregatorDetails?.customer_id
  );
  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setPreState({ ...preState, [name]: value });
  };
  useEffect(() => {
    if (LoanApplicationListData) {
      if (count === null) {
        setCount(LoanApplicationListData.totalCount);
      }
    }
  }, [LoanApplicationListData, count]);
  const isApplicationType = {
    SPARK: 1,
  };

  return (
    <div>
      {LoanApplicationListError && (
        <p className="alert alert-danger small">{LoanApplicationListError}</p>
      )}

      <div className=" d-flex justify-content-between">
        <h1 className={`${styles.loanAppTableHeading} color-blue-hover-none `}>
          Loan Application
        </h1>
        <button
          className="btn  advancly-btn  transition-3d-hover"
          disabled={EligibilitiyStatusData?.statusCode < 9 ? true : false}
          onClick={() => {
            setCurrentLoanApplicationId();
            setEditLoanApplicationGlobal(false);
            sessionStorage.removeItem("currentLoanAppId");
            sessionStorage.removeItem("globalEditLoanApplication");

            history.push(`loan-application-process`);
          }}
        >
          New Application
        </button>
      </div>
      <div>
        {EligibilitiyStatusData?.statusCode < 9 && (
          <div className="">
            <DismissableAlert dashboardAlert={true}>
              <span>
                Your account is currently pending onboarding approval. Kindly
                bear with us while we activate your account, after which you’ll
                be able to request a loan.
              </span>
            </DismissableAlert>
          </div>
        )}
        <div className="">
          <div className="d-flex justify-content-between mt-4">
            <div>
              <div className="input-group mb-3">
                <input
                  type="text"
                  className="mr-0 search-query form-control"
                  placeholder="Search By Reference"
                  name="searchValue"
                  onChange={(e) => handleChange(e)}
                />
                <div className="input-group-append">
                  <span className="input-group-text bg-white">
                    <span className="fa fa-search"></span>
                  </span>
                </div>
              </div>
            </div>
            <div className="d-flex align-items-center">
              <p className="pr-2 pt-1">Showing:</p>
              <CustomSelectDropdown
                onChange={(e: any) =>
                  setPreState({ ...preState, PageSize: e.target.value })
                }
                value={preState.PageSize}
              >
                <option value={10}>10 Entries</option>
                <option value={20}>20 Entries</option>
                <option value={50}>50 Entries</option>
                <option value={100}>100 Entries</option>
              </CustomSelectDropdown>
            </div>
          </div>
          {/* beginning of table */}
          {LoanApplicationListStatus === "loading" ? (
            <PageLoader />
          ) : (
            <div className="table-responsive">
              <table className="table">
                <thead className="bg-lighter-grey">
                  <tr>
                    <th className="text-center">Status</th>
                    <th>Tenure </th>
                    <th>Application Ref No</th>
                    <th className="text-center">Product</th>
                    <th>Date Applied</th>
                    <th> Proposed Amount</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {LoanApplicationListData && LoanApplicationListData?.data ? (
                    LoanApplicationListData?.data.map((data: any) => {
                      const {
                        id,
                        status,
                        ref_name,
                        application_date,
                        maximum_tenor,
                        proposed_products,
                        progress_status_id,
                        application_type_id,
                      } = data;
                      return (
                        <tr key={id}>
                          <td className="text-center">
                            <p className={StatusTag(status)}>
                              {" "}
                              {status ? status : "unavailable"}
                            </p>
                          </td>
                          <td>
                            {maximum_tenor ? maximum_tenor : "unavailable"}
                          </td>
                          <td>{ref_name ? ref_name : "unavailable"}</td>
                          <td>
                            {proposed_products
                              ? proposed_products?.map(
                                  ({ product }: { product: string }) => {
                                    return (
                                      <ul
                                        className={`${styles.listStyleType}`}
                                        key={uuidv4()}
                                      >
                                        <li>{product}</li>
                                      </ul>
                                    );
                                  }
                                )
                              : "unavailable"}
                          </td>
                          <td>
                            {application_date
                              ? application_date.substring(0, 10)
                              : ""}
                          </td>
                          <td>
                            {proposed_products
                              ? proposed_products?.map(
                                  ({
                                    grouped_sum,
                                    currency,
                                  }: {
                                    grouped_sum: number;
                                    currency:
                                      | "NGN"
                                      | "USD"
                                      | "KES"
                                      | "#"
                                      | "$"
                                      | "Ksh";
                                  }) => {
                                    return (
                                      <ul
                                        className={`${styles.listStyleType}`}
                                        key={uuidv4()}
                                      >
                                        <li>
                                          {formatMoney(grouped_sum, currency)}
                                        </li>
                                      </ul>
                                    );
                                  }
                                )
                              : "unavailable"}
                          </td>
                          {application_type_id === isApplicationType.SPARK && (
                            <td className="dropright text-center   ">
                              <span
                                className="more-icon-horizontal  "
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              ></span>
                              (
                              <div
                                className="dropdown-menu"
                                aria-labelledby="dropdownMenuButton"
                              >
                                <button
                                  className="btn btn-sm advancly-nobg-btn"
                                  name="Continue or View "
                                  onClick={() => {
                                    setCurrentLoanApplicationId(id);
                                    setEditLoanApplicationGlobal(true);
                                    sessionStorage.setItem(
                                      "currentLoanAppId",
                                      encrypt(id)
                                    );
                                    sessionStorage.setItem(
                                      "globalEditLoanApplication",
                                      encrypt(editLoanApplicationGlobal)
                                    );
                                    setContinueLoanRequestProgressBar(
                                      progress_status_id
                                    );

                                    history.push(`loan-application-process`);
                                  }}
                                >
                                  Continue
                                </button>
                              </div>
                              )
                            </td>
                          )}
                        </tr>
                      );
                    })
                  ) : (
                    <NoTableDataAvailable />
                  )}
                </tbody>
              </table>
            </div>
          )}
          <Paginator
            size={preState.PageSize}
            page={preState.page}
            count={count}
            changeCurrentPage={(page) => setPreState({ ...preState, page })}
          />
        </div>
      </div>
    </div>
  );
};

export default LoanApplicationTableList;
