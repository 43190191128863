import React from "react";
import CustomInputField from "../CustomHTMLElements/CustomInputField";
import CustomTextArea from "../CustomHTMLElements/CustomTextArea";
import LoaderColored from "../Loader/LoaderColored";

const EquityContribution = ({
  equitycontributionData,
  equitycontributionStatus,
}: any) => {
  return (
    <div>
      {equitycontributionStatus === "loading" && (
        <div>
          <LoaderColored />
        </div>
      )}
      <div className="row">
        <div className="col-6">
          <CustomInputField
            type="text"
            label="Item Amount"
            name="itemamount"
            readOnly
            value={equitycontributionData?.totalCostAmount}
          />
        </div>

        <div className="col-6">
          <CustomInputField
            type="text"
            label="Equity Percentage(%)"
            name="equitypercentage"
            readOnly
            value={equitycontributionData?.equityContributionPercentage}
          />
        </div>
        <div className="col-6">
          <CustomInputField
            type="text"
            label="Equity Amount"
            name="equityamount"
            readOnly
            value={equitycontributionData?.equityContributionAmount}
          />
        </div>
        <div className="col-6">
          <CustomInputField
            type="text"
            label="Percentage Discount Applied (%)"
            name="percentagediscountapplied"
            readOnly
            value={equitycontributionData?.costDiscountPercentage}
          />
        </div>
        <div className="col-6">
          <CustomTextArea
            label="Item Description"
            name="itemdescription"
            defaultValue={equitycontributionData?.description}
          />
        </div>
      </div>
    </div>
  );
};

export default EquityContribution;
