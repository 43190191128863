import {
  ChangeEvent,
  FormEvent,
  Fragment,
  useEffect,
  useRef,
  useState,
} from "react";
import CustomSelectDropdown from "../../../components/CustomHTMLElements/CustomSelectDropdown";
import Paginator from "../../../components/Paginator/Paginator.component";
import { useHistory, withRouter } from "react-router-dom";
import PageLoader from "../../../components/PageLoader/PageLoader.component";
import useDisbursementDetails from "../../../custom-hooks/useDisbursementDetails";
import { accurateQuantifier } from "../../../components/utility";
import { attachClassNameToTag } from "../../../helpers/attachClassNameToTag";
import { formatMoney } from "../../../helpers/formatter";
import NoTableDataAvailable from "../../../components/NoTableDataAvailable/NoTableDataAvailable.component";
import RollOverModal from "./AllDisbursedLoanModal/RollOverModal";
import { useAuth } from "../../../Context/auth.context";
import { postDataWithDotNet } from "../../../apis/dotNetApiMethods";
import { apiEndpoints } from "../../../apis/apiEndpoints";
import { useQueryCache } from "react-query";
import { errorHandler } from "../../../helpers/errorHandler";
import { appInsights } from "../../../components/AppInsight/AppInsight";
import ToolTip from "../../../components/ToolTip/ToolTip";
import CustomInputField from "../../../components/CustomHTMLElements/CustomInputField";
import LoaderColored from "../../../components/Loader/LoaderColored";
import useLoanStatus from "../../../custom-hooks/useLoanStatus";
import moment from "moment";
import { ReactComponent as ChevronDown } from "../../../svg/icons/chevrondown.svg";
import { ReactComponent as DownloadIcon } from "../../../svg/icons/download.svg";
import styles from "./AllDisbursedLoansOverview.module.scss";
import { postData, token } from "../../../apis/apiMethods";
import CONFIG from "../../../components/config";
import { MixPanel } from "../../../helpers/mixPanel";

interface Istate {
  page: number;
  PageSize: number;
  searchValue: string;
  fromdate?: "";
  todate?: "";
}
enum loanDownloadType {
  excel = "excel",
  csv = "csv",
}

function AllDisbursedLoansMain() {
  const [loanRef, setLoanRef] = useState("");
  const [loading, setLoading] = useState(false);
  const [modalActionType, setModalActionType] = useState("");
  const [submitError, setSubmitError] = useState("");
  const [submitSuccess, setSubmitSuccess] = useState("");
  const [showLoansToolTip, setShowLoansToolTip] = useState(false);
  const [count, setCount] = useState<any>(null);
  const [preState, setPreState] = useState({
    page: 1,
    PageSize: 10,
    searchValue: "",
  });
  const { aggregatorDetails } = useAuth();

  const [toggleFilterDownloadLoans, setToggleFilterDownloadLoans] =
    useState<Boolean>(false);
  const toggleRef = useRef<HTMLDivElement | null>(null); // Reference to the toggle div
  const filterCardRef = useRef<HTMLDivElement | null>(null); // Reference for card div

  const [state, setState] = useState<Istate>({
    page: 1,
    PageSize: 10,
    fromdate: "",
    todate: "",
    searchValue: "",
  });
  // const [fromDate, setFromDate] = useState("");
  // const [toDate, setToDate] = useState("");
  const [loanReferenceNo, setLoanReferenceNo] = useState("");
  const [loanStatus, setLoanStatus] = useState("");
  const [name, setName] = useState("");
  const [filterDownloadCsvStartDate, setFilterDownloadCsvStartDate] =
    useState("");
  const [filterDownloadCsvEndDate, setFilterDownloadCsvEndDate] = useState("");
  const [filterDownloadCsvStatus, setFilterDownloadCsvStatus] = useState<
    number | null
  >(null);
  const [currentDownloadType, setCurrentDownloadType] = useState<string | null>(
    null
  );
  const [downloadIsLoading, setDownloadIsLoading] = useState(false);
  const [downloadErrorMessage, setDownloadErrorMessage] = useState("");

  useEffect(() => {
    appInsights.trackPageView({
      name: "AllDisbursedLoansMain.tsx",
      isLoggedIn: true,
    });

    MixPanel.track(`Page View: AllDisbursedLoansMain.tsx`, {
      page: "AllDisbursedLoansMain.tsx - (Spark)",
    });
  }, []);

  const {
    data: loanStatusData,
    status: loanStat,
    error: loanStatusError,
  } = useLoanStatus();
  const { data, status, error } = useDisbursementDetails(preState);
  let history = useHistory();
  const dismissRef = useRef<HTMLButtonElement | null>(null);
  const queryCache = useQueryCache();
  // Get today's date in the format required by the 'date' input (YYYY-MM-DD)

  const today = new Date().toISOString().split("T")[0];

  const formatTime = (timestamp: string) => {
    const time = moment(timestamp);

    // Check if timestamp includes time, if not display 'No Time Provided'
    return time.isValid() && time.hours() !== 0
      ? time.format("hh:mm:ss A")
      : "No Time Provided";
  };
  const handleToggle = (event: React.MouseEvent<HTMLDivElement>) => {
    setToggleFilterDownloadLoans((prev) => !prev);
  };
  const handleResetCsvFilter = () => {
    setFilterDownloadCsvStartDate("");
    setFilterDownloadCsvEndDate("");
    setFilterDownloadCsvStatus(null);
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const filterData = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setCount(null);
    if (state.searchValue === "date_range") {
      setPreState((prevState) => ({
        ...prevState,
        ...state,
        page: 1,
        name: "",
        loanReferenceNo: "",
        loanStatus: "",
        // fromdate: fromDate,
        // todate: toDate,
      }));

      // setLoanReferenceNo("");
      // setLoanStatus("");
      // setName("");
      // setFromDate("");
      // setToDate("");
    } else if (state.searchValue === "loan_status") {
      setPreState((prevState) => ({
        ...prevState,
        ...state,
        loanStatus,
        name: "",
        fromdate: "",
        todate: "",
        loanReferenceNo: "",
      }));
    } else if (state.searchValue === "loanReferenceNo") {
      setPreState((prevState) => ({
        ...prevState,
        ...state,
        loanReferenceNo,
        name: "",
        fromdate: "",
        todate: "",
        loanStatus: "",
      }));
    } else if (state.searchValue === "name") {
      setPreState((prevState) => ({
        ...prevState,
        ...state,
        name,
        loanReferenceNo: "",
        fromdate: "",
        todate: "",
        loanStatus: "",
      }));
    } else if (state.searchValue === "") {
      setPreState((prevState) => ({
        ...prevState,
        ...state,
        name,
        loanReferenceNo: "",
        fromdate: "",
        todate: "",
        loanStatus: "",
      }));
    } else {
      setPreState((prevState) => ({
        ...prevState,
        ...state,
        page: 1,
      }));
    }
  };
  if (data) {
    if (count === null) {
      setCount(data.totalCount);
    }
  }
  const handleClick = (props: any) => {
    history.push({
      pathname: `/disbursements/${props.borrower_details.borrower_id}`,
      state: {
        loanRef: props.loan_ref,
        parent_rollover_loan_reference: props.parent_rollover_loan_reference,
        status: props.status,
        loan_id: props.loan_id,
      },
    });
  };
  const submitRepayment = async () => {
    setLoading(true);
    try {
      // eslint-disable-next-line no-unused-vars
      await postDataWithDotNet(
        `${apiEndpoints.finalAutoRepayment}?reference=${loanRef}`
      );
      setLoading(false);
      if (dismissRef && dismissRef.current) {
        dismissRef.current?.click();
      }
      setSubmitSuccess(
        "Repayment in being processed. Kindly check back in 10 minutes time to rollover the loan"
      );
    } catch (error) {
      appInsights.trackException({
        //@ts-ignore
        exception: error,
        properties: {
          fileName: "AllDisbursedLoansMain.tsx",
        },
      });
      setLoading(false);
      if (dismissRef && dismissRef.current) {
        dismissRef.current?.click();
      }
      queryCache.invalidateQueries("getDisbursementDetails");
      setSubmitError(errorHandler(error));
    }
  };
  const submitRollOverRequest = async (reqBody: { loan_tenure: string }) => {
    setLoading(true);
    const finalReqBody = {
      loanRef: loanRef,
      preferredLoanTenure: reqBody.loan_tenure,
      aggregatorId: aggregatorDetails?.aggregator_id,
    };
    try {
      await postData(`${apiEndpoints.initiateLoanRollOver}`, finalReqBody);
      setLoading(false);
      setModalActionType("step4");
    } catch (error) {
      appInsights.trackException({
        //@ts-ignore
        exception: error,
        properties: {
          fileName: "AllDisbursedLoansMain.tsx",
        },
      });
      setSubmitError(errorHandler(error));
      setLoading(false);
      queryCache.invalidateQueries("getDisbursementDetails");
      setModalActionType("step4");
    }
  };

  const handleButtonDownloadLoans = async (loanfileType: string) => {
    setCurrentDownloadType(loanfileType);
    setDownloadIsLoading(true);

    fetch(
      CONFIG.BASE_URL +
        `${
          apiEndpoints.DOWNLOAD_LOAN_LIST
        }?searchvalue=${"custom_range"}&fromdate=${filterDownloadCsvStartDate}&todate=${filterDownloadCsvEndDate}&loanStatus=${filterDownloadCsvStatus}&fileType=${loanfileType}`,
      {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Authorization: "Token " + token(),
        },
      }
    )
      .then((response) => {
        setDownloadIsLoading(false);

        if (response.status === 200 || response.status === 201) {
          response.blob().then((blob) => {
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement("a");
            link.href = url;
            if (loanfileType === "excel") {
              link.setAttribute(
                "download",
                `Loans ${filterDownloadCsvStartDate} to ${filterDownloadCsvEndDate}.xlsx`
              );
              link.click();
            } else {
              link.setAttribute(
                "download",
                `Loans ${filterDownloadCsvStartDate} to ${filterDownloadCsvEndDate}.csv`
              );
              link.click();
            }
          });
        }

        if (response.status === 400) {
          setDownloadIsLoading(false);
          response.json().then((res) => {
            setDownloadErrorMessage(res.error[0]);
          });

          setTimeout(() => {
            setDownloadErrorMessage("");
          }, 3000);
        }

        if (response.status === 403 || response.status === 401) {
          localStorage.removeItem("sso_auth_token");
          history.push("/login");
        }

        if (response.status >= 500) {
          setDownloadIsLoading(false);
          response.json().then((res) => {
            setDownloadErrorMessage(res.error[0]);
          });
          setTimeout(() => {
            setDownloadErrorMessage("");
          }, 3000);
        }
      })
      .catch((error) => {
        setDownloadErrorMessage(error?.response?.message);
        setTimeout(() => {
          setDownloadErrorMessage("");
        }, 3000);
        appInsights.trackException({
          exception: error,
          properties: {
            fileName: "AllDisbursedLoanMain.tsx",
          },
        });
        setDownloadIsLoading(false);
      });
  };

  // Effect to handle clicks outside of the toggle div and card div
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as Node;

      // Check if the click is outside the toggle and card divs
      if (
        toggleRef.current &&
        !toggleRef.current.contains(target) &&
        filterCardRef.current &&
        !filterCardRef.current.contains(target)
      ) {
        setToggleFilterDownloadLoans(false);
      }
    };

    // Add event listener for outside click
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup event listener on unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  if (status === "loading") return <PageLoader />;

  return (
    <div>
      {error && <p className="alert alert-danger small">{error as string}</p>}
      {submitError && <p className="alert alert-danger small">{submitError}</p>}
      {downloadErrorMessage && (
        <p className="alert alert-danger small">{downloadErrorMessage}</p>
      )}
      {loanStatusError && (
        <p className="alert alert-danger small">{loanStatusError as string}</p>
      )}
      {submitSuccess && (
        <p className="alert alert-success small">{submitSuccess}</p>
      )}
      {
        //@ts-ignore
        !error && status !== "loading" && (
          <div>
            <div className="advancly-wrapper-container">
              <div className="advancly-sub-wrapper">
                <div
                  className="d-flex justify-content-between align-items-center flex-wrap"
                  style={{ position: "relative" }}
                >
                  <form
                    className="d-flex flex-row flex-wrap align-items-center    custom-search-input mb-2"
                    onSubmit={filterData}
                  >
                    <div style={{ width: "150px", marginRight: "8px" }}>
                      <CustomSelectDropdown
                        name="searchValue"
                        value={state.searchValue}
                        onChange={(e: any) => handleChange(e)}
                      >
                        <option value="">All</option>
                        <option value="today">Today</option>
                        <option value="this_week">This week</option>
                        <option value="last_7_days">last 7 days</option>
                        <option value="last_30_days">last 30 days</option>
                        <option value="last_365_days">last 365 days</option>
                        <option value="date_range">Date range</option>
                        <option value="loan_status">Loan Status</option>
                        <option value="loanReferenceNo">Loan Reference</option>
                        <option value="name">Customer Name</option>
                      </CustomSelectDropdown>
                    </div>
                    {state.searchValue === "date_range" && (
                      <Fragment>
                        <div className="form-group mr-3">
                          <div className="input-group mt-2">
                            <label className="mr-2 mt-2">From </label>
                            <input
                              type="date"
                              name="fromdate"
                              className="form-control"
                              value={state.fromdate}
                              onChange={(e: any) => handleChange(e)}
                            />
                          </div>
                        </div>
                        <div className="form-group mr-3 mt-2">
                          <div className="input-group">
                            <label className="mr-2 mt-2">To </label>
                            <input
                              type="date"
                              className="form-control"
                              name="todate"
                              value={state.todate}
                              onChange={(e: any) => handleChange(e)}
                            />
                          </div>
                        </div>
                      </Fragment>
                    )}

                    {state.searchValue === "loan_status" && (
                      <Fragment>
                        <div className="form-group pl-2  mr-3">
                          <div className="input-group mt-2">
                            <label className="mr-2 mt-3">Loan Status </label>

                            <CustomSelectDropdown
                              name="loanStatus"
                              value={loanStatus}
                              onChange={(e) =>
                                setLoanStatus(
                                  (e.target as HTMLInputElement).value
                                )
                              }
                            >
                              <option value="">All</option>
                              {loanStatusData &&
                                loanStatusData?.map(
                                  ({
                                    name,
                                    id,
                                  }: {
                                    name: string;
                                    id: number;
                                  }) => {
                                    return (
                                      <option key={id} value={id}>
                                        {name.replace(/_/g, " ")}
                                      </option>
                                    );
                                  }
                                )}
                            </CustomSelectDropdown>
                            {loanStat === "loading" && <LoaderColored />}
                          </div>
                        </div>
                      </Fragment>
                    )}
                    {state.searchValue === "loanReferenceNo" && (
                      <Fragment>
                        <div className="form-group pl-2  mr-3">
                          <div className="input-group mt-2">
                            <label className="mr-2 mt-4">Loan Reference </label>
                            <div className="mt-3">
                              <CustomInputField
                                type="search"
                                name="loanReferenceNo"
                                value={loanReferenceNo}
                                onChange={(e: any) =>
                                  setLoanReferenceNo(e.target.value)
                                }
                                placeholder="search by reference"
                              ></CustomInputField>
                            </div>
                          </div>
                        </div>
                      </Fragment>
                    )}
                    {state.searchValue === "name" && (
                      <Fragment>
                        <div className="form-group pl-2  mr-3">
                          <div className="input-group mt-2">
                            <label className="mr-2 mt-4">Customer Name </label>
                            <div className="mt-3">
                              <CustomInputField
                                type="search"
                                name="name"
                                value={name}
                                onChange={(e: any) => setName(e.target.value)}
                                placeholder="search by customer name"
                              ></CustomInputField>
                            </div>
                          </div>
                        </div>
                      </Fragment>
                    )}
                    <div className="mb-2">
                      <button
                        type="submit"
                        className="btn advancly-btn "
                        value="submit"
                        //@ts-ignore
                        disabled={status === "loading"}
                      >
                        Search
                      </button>
                    </div>
                  </form>

                  <div>
                    <div
                      ref={toggleRef}
                      className={`form-group ${styles.dropDownfilterContainer}`}
                      onClick={handleToggle}
                    >
                      <div className={styles.dropDownfilterSubContainer}>
                        <p>
                          Filter By{" "}
                          <span className="ml-1">
                            {" "}
                            <ChevronDown />
                          </span>
                        </p>
                      </div>
                    </div>
                    {toggleFilterDownloadLoans && (
                      <div
                        className={styles.dropdownFilterCardContainer}
                        ref={filterCardRef}
                      >
                        <h5>Filters</h5>

                        <div className="row">
                          <div className="col-12">
                            <CustomInputField
                              name="filterdownloadstartdate"
                              type="date"
                              label="Start Date"
                              max={today}
                              value={filterDownloadCsvStartDate}
                              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                setFilterDownloadCsvStartDate(e.target.value)
                              }
                            />
                            {!filterDownloadCsvStartDate && (
                              <span className="text-danger">
                                Please select start date
                              </span>
                            )}
                          </div>
                          <div className="col-12">
                            <CustomInputField
                              name="filterdownloadenddate"
                              type="date"
                              label="End Date"
                              max={today}
                              value={filterDownloadCsvEndDate}
                              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                setFilterDownloadCsvEndDate(e.target.value)
                              }
                            />
                            {!filterDownloadCsvEndDate && (
                              <span className="text-danger">
                                Please select end date
                              </span>
                            )}
                          </div>
                          <div className="col-12">
                            <CustomSelectDropdown
                              name="filterdownloadstatus"
                              label="status"
                              value={String(filterDownloadCsvStatus)}
                              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                setFilterDownloadCsvStatus(
                                  Number(e.target.value)
                                )
                              }
                            >
                              <option value="">All</option>
                              {loanStatusData &&
                                loanStatusData?.map(
                                  ({
                                    name,
                                    id,
                                  }: {
                                    name: string;
                                    id: number;
                                  }) => {
                                    return (
                                      <option key={id} value={id}>
                                        {name.replace(/_/g, " ")}
                                      </option>
                                    );
                                  }
                                )}
                            </CustomSelectDropdown>
                          </div>
                          <div className="col-12 ">
                            <div className="d-flex flex-wrap">
                              <div>
                                <button
                                  className={styles.downloadButton}
                                  disabled={
                                    !filterDownloadCsvStartDate &&
                                    !filterDownloadCsvEndDate
                                  }
                                  onClick={() =>
                                    handleButtonDownloadLoans(
                                      loanDownloadType.csv
                                    )
                                  }
                                >
                                  {" "}
                                  <span>
                                    <DownloadIcon />
                                  </span>
                                  Download CSV{" "}
                                  {currentDownloadType ===
                                    loanDownloadType.csv &&
                                    downloadIsLoading && <LoaderColored />}
                                </button>
                              </div>
                              <div className="ml-3">
                                <button
                                  className={`${styles.downloadButton} `}
                                  disabled={
                                    !filterDownloadCsvStartDate &&
                                    !filterDownloadCsvEndDate
                                  }
                                  onClick={() =>
                                    handleButtonDownloadLoans(
                                      loanDownloadType.excel
                                    )
                                  }
                                >
                                  <span>
                                    <DownloadIcon />
                                  </span>
                                  Download Excel{" "}
                                  {currentDownloadType ===
                                    loanDownloadType.excel &&
                                    downloadIsLoading && <LoaderColored />}
                                </button>
                              </div>
                            </div>
                          </div>
                          {/* Rest and Apply */}
                          <div className="col-12 mt-3">
                            <div className="d-flex">
                              <button
                                className={styles.resetButton}
                                onClick={handleResetCsvFilter}
                              >
                                Reset
                              </button>
                              {/* <button className={`${styles.applyButton} ml-3`}>
                                Apply
                              </button> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <div
                    className="d-flex align-items-center"
                    onMouseEnter={() => setShowLoansToolTip(true)}
                    onMouseLeave={() => setShowLoansToolTip(false)}
                  >
                    <p className="pr-2 pt-1">Showing:</p>
                    <CustomSelectDropdown
                      onChange={(e) =>
                        //@ts-ignore
                        setPreState({ ...state, PageSize: e.target?.value })
                      }
                      value={preState.PageSize}
                      //@ts-ignore

                      changeCurrentPage={(page) =>
                        setPreState({ ...state, page })
                      }
                    >
                      <option value={10}>10 Entries</option>
                      <option value={20}>20 Entries</option>
                      <option value={50}>50 Entries</option>
                      <option value={100}>100 Entries</option>
                    </CustomSelectDropdown>

                    {showLoansToolTip && (
                      <ToolTip
                        tooltipContainerRight={"1rem"}
                        tooltipContainerTop={"5rem"}
                        tooltipHeading={"Entries"}
                        tooltipParagraph={`Maximise your overview: Select the number of entries to view multiple loans on a single page and effortlessly compare your options`}
                      />
                    )}
                  </div>
                </div>
                <div className="table-responsive">
                  <table className="table-style">
                    <thead className="">
                      <tr>
                        <th className="th-td-style th-style ">Reference</th>
                        <th className="th-td-style th-style ">Borrower</th>
                        <th className="th-td-style th-style ">Product</th>
                        <th className="th-td-style th-style ">Amount</th>
                        <th className="th-td-style th-style ">Tenure</th>
                        <th className="th-td-style th-style ">Interest</th>
                        <th className="th-td-style th-style ">Outstanding</th>
                        <th className="th-td-style th-style ">Borrower Type</th>
                        <th className="th-td-style th-style ">
                          {" "}
                          Date Created{" "}
                        </th>
                        <th className="th-td-style th-style ">Time Stamp</th>
                        <th className="th-td-style th-style ">Loan Status</th>
                        {/* <th className="th-td-style th-style ">Roll Over</th>
                        <th className="th-td-style th-style ">
                          Roll Over Status
                        </th> */}
                        <th className="th-td-style th-style ">Roll Over Ref</th>
                        <th className="th-td-style th-style ">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data && data?.data?.length ? (
                        data?.data?.map((data: any, id: number) => {
                          const {
                            borrower_details: {
                              first_name,
                              last_name,
                              borrower_type_value,
                            },
                            loan_ref,
                            pub_date,
                            loan_amount,
                            product_name,
                            loan_tenure,
                            loan_outstanding,
                            loan_interest_amount,
                            status,
                            currency,
                            // is_rollover,
                            rollover_loan_reference,
                            // can_rollover,
                          } = data;

                          return (
                            <tr key={id}>
                              <td className="th-td-style ">{loan_ref}</td>
                              <td
                                style={{ minWidth: "180px" }}
                                className="text-capitalize th-td-style "
                              >
                                {first_name && first_name.toLowerCase()}{" "}
                                {last_name && last_name.toLowerCase()}
                              </td>
                              <td
                                className="th-td-style "
                                style={{ minWidth: "150px" }}
                              >
                                {product_name}
                              </td>
                              <td className="th-td-style ">
                                {formatMoney(loan_amount, currency)}
                              </td>
                              <td
                                className="th-td-style "
                                style={{ minWidth: "150px" }}
                              >
                                {accurateQuantifier(loan_tenure, "day")}
                              </td>
                              <td className="th-td-style ">
                                {formatMoney(loan_interest_amount, currency)}
                              </td>
                              <td className="th-td-style ">
                                {formatMoney(loan_outstanding, currency)}
                              </td>
                              <td className="th-td-style ">
                                {borrower_type_value}
                              </td>
                              <td
                                className="th-td-style "
                                style={{ minWidth: "120px" }}
                              >
                                {pub_date.substring(0, 10)}
                              </td>
                              <td
                                className="th-td-style "
                                style={{ minWidth: "120px" }}
                              >
                                {formatTime(pub_date)}
                              </td>
                              <td className="th-td-style ">
                                <span className={attachClassNameToTag(status)}>
                                  {attachClassNameToTag(status)}
                                </span>
                              </td>
                              {/* Loan Rollover has been temporarily removed for customers till Business decides to bring it back */}

                              {/* {can_rollover === true ? (
                                <td
                                  className="color-mid-blue cursor-pointer th-td-style "
                                  onClick={() => {
                                    setLoanRef(loan_ref);
                                    setModalActionType("step1");
                                  }}
                                  style={{ minWidth: "120px" }}
                                  data-toggle="modal"
                                  data-target="#rollOverModal"
                                >
                                  Apply
                                </td>
                              ) : (
                                <td className="text-secondary th-td-style">
                                  Not available
                                </td>
                              )}
                              <td className="th-td-style ">
                                {is_rollover ? "True" : "False"}
                              </td> */}
                              <td className="th-td-style ">
                                {rollover_loan_reference || "N/A"}
                              </td>
                              <td
                                className="color-mid-blue cursor-pointer th-td-style"
                                onClick={() => handleClick(data)}
                                style={{ minWidth: "120px" }}
                              >
                                View Details
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <NoTableDataAvailable />
                      )}
                    </tbody>
                  </table>
                </div>
                {data && (
                  <Paginator
                    size={preState.PageSize}
                    page={preState.page}
                    count={count}
                    changeCurrentPage={(page) =>
                      setPreState({ ...state, page })
                    }
                  />
                )}
              </div>
            </div>
          </div>
        )
      }

      <RollOverModal
        modalActionType={modalActionType}
        setModalActionType={setModalActionType}
        loanRef={loanRef}
        aggregatorDetails={aggregatorDetails}
        submitRepayment={submitRepayment}
        submitRollOverRequest={submitRollOverRequest}
        loading={loading}
        submitError={submitError}
        dismissRef={dismissRef}
      />
    </div>
  );
}
export default withRouter(AllDisbursedLoansMain);
