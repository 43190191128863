import { useQuery } from "react-query";
import { apiEndpoints } from "../apis/apiEndpoints";
import { getData } from "../apis/apiMethods";
interface IgetDisbursementDetails {
  page: number;
  PageSize: number;
  aggregatorId?: number;
}
const getDisbursementLoanDetails = async ({
  page,
  PageSize,
  aggregatorId,
}: IgetDisbursementDetails) => {
  let queryParams = `?aggregatorId=${aggregatorId}&Page=${page}&PageSize=${PageSize}`;

  const data = await getData(
    `${apiEndpoints.loanApplicationDetailDisbursement}${queryParams}`
  );
  return data;
};

export default function useDisbursementLoanDetails({
  page,
  PageSize,
  aggregatorId,
}: IgetDisbursementDetails) {
  return useQuery(
    [{ aggregatorId, page, PageSize }, "getDisbursementLoanDetails"],
    getDisbursementLoanDetails,
    {
      enabled: aggregatorId && page && PageSize ? true : false,
      refetchOnWindowFocus: false,
    }
  );
}
