import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import CustomInputField from "../../../../../components/CustomHTMLElements/CustomInputField";
import CustomSelectDropdown from "../../../../../components/CustomHTMLElements/CustomSelectDropdown";
import useGetShareholders from "../../../../../custom-hooks/useGetShareholders";
import { useAuth } from "../../../../../Context/auth.context";
import { apiEndpoints } from "../../../../../apis/apiEndpoints";
import { postData, putData } from "../../../../../apis/apiMethods";
import { appInsights } from "../../../../../components/AppInsight/AppInsight";
import { errorHandler } from "../../../../../helpers/errorHandler";
import { useQueryCache } from "react-query";
import Loader from "../../../../../components/Loader/Loader.component";
import { yupValidators } from "../../../../../helpers/yupValidators";
import { MixPanel } from "../../../../../helpers/mixPanel";
type TchildShareholder = {
  shareholderType: string | number;
  shareholderName: string;
  shareholderLastName: string;
  shareholderCurrency: string;
  valueofsharesamount: string | number;
  percentOfShares: number | string;
  parentshareholder: number;
};
type TProps = {
  editShareholderTable: boolean;
  setEditShareholderTable: Dispatch<SetStateAction<boolean>>;
  fullShareholderData?: any;
};
const schema = yup.object({
  shareholderType: yupValidators.shareholderType,
  shareholderName: yupValidators.shareholderName,
  percentOfShares: yupValidators.percentOfShares,
});
const GetStartedAddChildShareholderModal = ({
  editShareholderTable,
  setEditShareholderTable,
  fullShareholderData,
}: TProps) => {
  const shareholdertype = {
    individual: "1",
    corporate: "2",
  };
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const currentShareholderType = shareholdertype?.corporate;
  const [error, setError] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const dismissRef = useRef<HTMLButtonElement>(null);
  const queryCache = useQueryCache();

  const { aggregatorDetails } = useAuth();
  const {
    data: shareholdersParentListData,
    isLoading: shareholdersParentListLoading,
    error: shareholdersParentListErrors,
  } = useGetShareholders({
    funderId: aggregatorDetails?.customer_id,
    Type: 2,
  });
  //the above type =2 is corporate
  const { register, handleSubmit, errors, setValue, reset } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    MixPanel.track(`Page View: GetStartedAddShareHolder.tsx`, {
      page: "GetStartedAddShareHolder.tsx - (Spark)",
    });
  }, []);

  useEffect(() => {
    if (
      fullShareholderData?.parent_shareholder_id !== null &&
      editShareholderTable
    ) {
      setTimeout(() => {
        if (fullShareholderData?.shareholder_type === "Individual") {
          setValue("shareholderType", 1);
        } else {
          setValue("shareholderType", 2);
        }
      }, 2000);
      setValue("shareholderName", fullShareholderData?.name);

      setTimeout(() => {
        setValue("shareholderCurrency", fullShareholderData?.currency);
      }, 3000);
      setValue("percentOfShares", fullShareholderData?.percentage_shareholding);
      setTimeout(() => {
        setValue(
          "parentshareholder",
          fullShareholderData?.parent_shareholder_id
        );
      }, 4000);
    }
  }, [editShareholderTable, fullShareholderData, setValue]);

  const onSubmit = async (childshareholderdata: TchildShareholder) => {
    const {
      shareholderType,
      shareholderName,
      shareholderLastName,
      shareholderCurrency,
      percentOfShares,
      parentshareholder,
    } = childshareholderdata;
    setLoading(true);
    setError(false);
    const reqBody = {
      // name: shareholderName,

      firstname: shareholderName,
      lastname: shareholderLastName,
      currency: shareholderCurrency,
      percentage_shareholding: Number(percentOfShares),
      type: Number(shareholderType),
      parent_shareholder_id: Number(parentshareholder),
    };
    try {
      let res;
      if (editShareholderTable) {
        res = await putData(
          `${apiEndpoints.SHAREHOLDER_ONBOARDING}`,
          [{ ...reqBody, id: fullShareholderData?.id }],
          true
        );
      } else {
        res = await postData(
          `${apiEndpoints.SHAREHOLDER_ONBOARDING}`,
          [reqBody],
          true
        );
      }

      setLoading(false);
      setSuccess(true);
      setSuccessMessage(res?.message);
      setTimeout(() => {
        setSuccess(false);
        setEditShareholderTable(false);
        reset();
        dismissRef?.current?.click();
      }, 3000);

      queryCache.invalidateQueries([
        { funderId: aggregatorDetails?.customer_id, Type: null },
        "getShareholders",
      ]);
      queryCache.invalidateQueries([
        { funderId: aggregatorDetails?.customer_id, Type: 2 },
        "getShareholders",
      ]);
    } catch (error) {
      appInsights.trackException({
        //@ts-ignore
        exception: error,
        properties: { fileName: "GetStartedAddChildShareholderModal.tsx" },
      });
      setLoading(false);
      setError(errorHandler(error));
      setEditShareholderTable(false);

      setTimeout(() => {
        setError(false);
      }, 10000);
    }
  };
  return (
    <div className="">
      <div
        className="modal fade "
        id="addChildShareholderModal"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="addChildShareholderModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-top" role="document">
          <div className="modal-content p-4 ">
            {shareholdersParentListErrors && (
              <p className="alert alert-danger">
                {shareholdersParentListErrors}
              </p>
            )}
            {error && <p className="alert alert-danger">{error}</p>}
            {success && <p className="alert alert-success">{successMessage}</p>}
            <div className="d-flex justify-content-between p-3">
              <h5 className="modal-title" id="addChildShareholderModalLabel">
                Add Ultimate Beneficiary
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                ref={dismissRef}
                onClick={() => {
                  reset();
                  setEditShareholderTable(false);
                }}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row py-2">
                  <>
                    <div className="col-12">
                      <CustomInputField
                        type="text"
                        maxLength={128}
                        ref={register}
                        name="shareholderName"
                        placeholder="Enter shareholder's First Name"
                        label="Shareholder's First Name"
                        errors={errors.shareholderName}
                        showRequiredIcon={true}
                      />
                    </div>
                    <div className="col-12">
                      <CustomInputField
                        type="text"
                        maxLength={128}
                        ref={register}
                        name="shareholderLastName"
                        placeholder="Enter shareholder's Last Name"
                        label="Shareholder's Last Name"
                        errors={errors.shareholderName}
                        showRequiredIcon={true}
                      />
                    </div>
                  </>

                  <div className="col-12">
                    <CustomSelectDropdown
                      ref={register}
                      name="shareholderType"
                      label="Shareholder Type"
                      errors={errors.shareholderType}
                      showRequiredIcon={true}
                    >
                      <option value="">Select shareholder type</option>
                      <option value="1">Individual</option>

                      <option value="2">Corporate</option>
                    </CustomSelectDropdown>
                  </div>
                  <div className="col-12">
                    <CustomSelectDropdown
                      ref={register}
                      name="parentshareholder"
                      label="Parent Shareholder"
                      errors={errors.parentshareholder}
                      showRequiredIcon={true}
                      extraLabel={
                        shareholdersParentListLoading ? "Loading...." : ""
                      }
                    >
                      <option value="">
                        Select parent shareholder to add child
                      </option>
                      {shareholdersParentListData?.map(
                        (parentData: {
                          id: number;
                          firstname: string;
                          lastname: string;
                        }) => {
                          const { id, firstname, lastname } = parentData;
                          return (
                            <option key={id} value={id}>
                              {`${firstname}  ${lastname ? lastname : ""}`}
                            </option>
                          );
                        }
                      )}
                    </CustomSelectDropdown>
                  </div>
                  <div className="col-12">
                    <CustomInputField
                      type="number"
                      maxLength={5}
                      ref={register}
                      name="percentOfShares"
                      placeholder="Enter share percentage "
                      label="Percentage of shareholding"
                      errors={errors.percentOfShares}
                      extraLabel={"%"}
                      showRequiredIcon={true}
                      step="any"
                    />
                  </div>
                </div>
                <div className="col-12">
                  <button
                    type="submit"
                    disabled={loading}
                    className="btn advancly-btn w-100"
                  >
                    Save and add another {loading && <Loader />}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GetStartedAddChildShareholderModal;
