import { useQuery } from "react-query";
import { apiEndpoints } from "../apis/apiEndpoints";
import { getData } from "../apis/apiMethods";

const getEquityContribution = async ({
  loanReference,
}: {
  loanReference: string;
}) => {
  const data = await getData(
    `${apiEndpoints.EQUITY_CONTRIBUTION_DETAIL}?loanRef=${loanReference}`
  );
  return data.data;
};

export default function useEquityContribution({
  loanReference,
}: {
  loanReference: string;
}) {
  return useQuery(
    [{ loanReference }, "getEquityContribution"],
    getEquityContribution,
    {
      enabled: loanReference ? true : false,
      refetchOnWindowFocus: false,
    }
  );
}
