import React, { useState } from "react";
import { Space, Table } from "antd";

import type { ColumnsType } from "antd/es/table";
import ConfirmationDeleteShareholderModal from "./ConfirmationDeleteShareholderModal";
import GetStartedAddChildShareholderModal from "./GetStartedAddChildShareholderModal";
interface DataType {
  key: React.ReactNode;
  shareholdertype: string;
  shareholdername: string;
  numberofshares: string;
  valueofshares: number;
  percentageshares: string;
  percentagesharesremaining: string;
  children?: DataType[];
}

const GetStartedShareholdersList = ({
  shareholdersListData,
  shareholdersListLoading,
  editShareholderTable,
  setEditShareholderTable,
  fullShareholderData,
  setFullshareholderData,
  setIsAddParentShareholder,
  shareholderListStatus,
}: any) => {
  const [currentShareholderId, setCurrentShareholderId] = useState<
    number | null | any
  >(null);
  const [currentShareholderParentId, setCurrentShareholderParentId] = useState<
    number | null | any
  >(1);
  const columns: ColumnsType<DataType> = [
    {
      title: "Shareholder Type",
      dataIndex: "shareholder_type",
      key: "shareholder_type",
    },

    {
      title: "Shareholder Name",
      dataIndex: "firstname",
      key: "firstname",
    },
    {
      title: "Shareholder Last Name",
      dataIndex: "lastname",
      key: "lastname",
      render: (value) => {
        if (value === "" || value === null) {
          return "-";
        } else {
          return `${value.toLocaleString()}`;
        }
      },
    },
    {
      title: "% Shares",
      dataIndex: "percentage_shareholding",
      key: "percentage_shareholding",
    },
    {
      title: "No of Shares",
      dataIndex: "number_of_shares",
      key: "number_of_shares",
      render: (value) => {
        if (value === 0) {
          return "-";
        } else {
          return `${value.toLocaleString()}`;
        }
      },
    },
    {
      title: "Value of Shares(Amount)",
      dataIndex: "amount",
      key: "amount",
      render: (value, record: any) => {
        if (value === 0) {
          return "-";
        } else {
          let currency;
          if (record.currency === "NGN" || record.currency === "Naira") {
            currency = "₦";
          } else if (
            record.currency === "USD" ||
            record.currency === "US Dollar"
          ) {
            currency = "$";
          } else if (
            record.currency === "KES" ||
            record.currency === "Kenyan Shilling"
          )
            currency = "Ksh";
          else if (
            record.currency === "TZA" ||
            record.currency === "Tanzania Shilling"
          )
            currency = "TSh";
          else {
            currency = "";
          }
          return `${currency}${value.toLocaleString()}`;
        }
      },
    },

    {
      title: "Action",
      key: "action",
      render: (_, tableData: any) => (
        <Space size="middle">
          <span
            className="color-mid-blue cursor-pointer"
            data-toggle={
              tableData?.parent_shareholder_id !== null ? `modal` : ""
            }
            data-target={
              tableData?.parent_shareholder_id !== null
                ? "#addChildShareholderModal"
                : ""
            }
            onClick={() => {
              setEditShareholderTable(true);
              setFullshareholderData(tableData);
              if (tableData?.parent_shareholder_id === null) {
                setIsAddParentShareholder(true);
              }
            }}
          >
            Edit
          </span>
          <span
            className="crimson cursor-pointer"
            data-toggle="modal"
            data-target="#confirmationDeleteShareholderModal"
            onClick={() => {
              setCurrentShareholderId(tableData?.key);
              setCurrentShareholderParentId(tableData);
            }}
          >
            Delete
          </span>
        </Space>
      ),
    },
  ];

  return (
    <div>
      <Table
        loading={shareholdersListLoading}
        columns={columns}
        dataSource={shareholdersListData ? shareholdersListData : []}
        pagination={false}
        size="small"
      />

      <ConfirmationDeleteShareholderModal
        currentId={currentShareholderId}
        setCurrentId={setCurrentShareholderId}
        currentParentShareholderId={currentShareholderParentId}
        setCurrentParentShareholderId={setCurrentShareholderParentId}
      />
      <GetStartedAddChildShareholderModal
        editShareholderTable={editShareholderTable}
        setEditShareholderTable={setEditShareholderTable}
        fullShareholderData={fullShareholderData}
      />
    </div>
  );
};

export default GetStartedShareholdersList;
